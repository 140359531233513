var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      attrs: {
        wrap: "",
        "justify-start": "",
        "pa-2": "",
        "pa-sm-4": "",
        "pa-lg-8": "",
      },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "py-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "app-border-color",
                      attrs: { outlined: "", rounded: "lg" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", sm4: "", lg3: "" } },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(" " + _vm._s(_vm.$t("form.name")) + " "),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "title-3" }, [
                                _vm._v(" " + _vm._s(_vm.department.name) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", sm4: "", lg3: "" } },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.phone")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "title-3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.department.phone) + " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs6: "",
                                sm4: "",
                                lg3: "",
                                "pt-4": "",
                                "pt-sm-0": "",
                              },
                            },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.address")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "title-3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.department.address) + " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "py-4": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "title-2", attrs: { xs12: "", "pb-4": "" } },
                [_vm._v(" " + _vm._s(_vm.$t("pages.employees.title")) + " ")]
              ),
              _c("v-flex", { attrs: { xs12: "", md3: "", lg5: "", xl7: "" } }),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    md9: "",
                    lg7: "",
                    xl5: "",
                    "py-2": "",
                    "align-self-center": "",
                  },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-end": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            md3: "",
                            "pr-2": "",
                            "text-right": "",
                            "align-self-center": "",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("form.gender"),
                              items: _vm.GENDERS,
                              "item-value": "value",
                              "item-text": "label",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getEmployeesList(false)
                              },
                            },
                            model: {
                              value: _vm.filter.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "gender", $$v)
                              },
                              expression: "filter.gender",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            md3: "",
                            "pr-2": "",
                            "text-right": "",
                            "align-self-center": "",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("form.qualification"),
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              items: _vm.qualifications,
                              "item-text": "name",
                              "item-value": "id",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getEmployeesList(false)
                              },
                            },
                            model: {
                              value: _vm.filter.qualifications,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "qualifications", $$v)
                              },
                              expression: "filter.qualifications",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            md3: "",
                            "pr-2": "",
                            "text-right": "",
                            "align-self-center": "",
                          },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: _vm.$t("form.search"),
                              type: "search",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "append-icon": "mdi-magnify",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getEmployeesList(false)
                              },
                            },
                            model: {
                              value: _vm.filter.search,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "search", $$v)
                              },
                              expression: "filter.search",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("AppSimpleTable", {
                    attrs: {
                      headings: _vm.headings,
                      items: _vm.list,
                      pagination: _vm.pagination,
                      "empty-title": _vm.$t("messages.info.no_items", {
                        type: _vm.$t("pages.employees.assignments"),
                      }),
                    },
                    on: {
                      paginate: (val) => (_vm.pagination.page = val),
                      delete: _vm.toggleDelete,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DeleteConfirmation", {
        attrs: { item: _vm.selectedItem },
        on: { cancel: _vm.toggleDelete, submit: _vm.confirmDeleteEmployee },
        model: {
          value: _vm.showDelete,
          callback: function ($$v) {
            _vm.showDelete = $$v
          },
          expression: "showDelete",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }